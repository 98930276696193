.app {
  text-align: center;
  margin-top: 50px;
}

.question {
  font-size: 18px;
  margin-bottom: 20px;
}

.answerButton {
  background-color: #0039A6;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  margin: 0 10px;
  cursor: pointer;
}

.answerButton:hover {
  background-color: #002776;
}

.endMessage {
  font-size: 18px;
  margin-top: 20px;
}
